import { useState } from "react";
import { ethers } from "ethers";


function Signature(props) {

  const [message, setMessage] = useState();
  const [signature, setSignature] = useState();
  const [address, setAddress] = useState();
  const [recoveredAddress, setRecoveredAddress] = useState();

  const verify = () => {
    setRecoveredAddress(ethers.verifyMessage(message, signature));
  };

  return (
    <div className="w-full rounded overflow-hidden shadow-lg p-4 bg-emerald-600 bg-neutral-800 bg-opacity-40">
      {/* <img className="w-full" src="/img/card-top.jpg" alt="Sunset in the mountains"/> */}
      <div className="px-6 py-4">
        <div className="font-bold text-3xl mb-6 text-white">Parašo tikrinimas</div>
        <p className="text- text-white"><ul className="list-disc mb-3">
          <h3 className="text-2xl mb-2">Žemiau pateikdami informaciją:</h3>
          <li className="mx-6">Pasirašytas pranešimas.</li>
          <li className="mx-6">Sugeneruotas parašo kodas.</li>
          <li className="mx-6">Pasirašančio piniginės adresas. (neprivaloma)</li>
        </ul>
        Skaitmeninio parašo tikrinimo paslauga suteikia patikimą būdą patikrinti skaitmeniniu būdu pasirašytų pranešimų autentiškumą ir vientisumą, o tai gali būti ypač svarbu teisinėms ir finansinėms operacijoms, kurioms reikalingas aukštas saugumo ir pasitikėjimo lygis.        </p>
      </div>
      <div className="px-6 pb-2 pt-4">
        <div className="text-sm text-white px-4">Pranešimas:</div>

        <textarea onChange={(e) => setMessage(e.target.value)} className="w-full h-16 px-4 py-2 rounded-lg border-gray-400 border-2 focus:outline-none focus:border-blue-500 " rows="2" placeholder="Tekstas...."></textarea>

        <div className="text-sm text-white px-4">Parašas:</div>
        <textarea
          onChange={(e) => setSignature(e.target.value)}
          type="text"
          className="w-full h-16 px-4 py-2 rounded-lg border-gray-400 border-2 focus:outline-none focus:border-blue-500 " rows="2" placeholder="Parašo kodas" />
        <div className="text-sm text-white px-4">Pasiračančio piniginės adresas: (neprivaloma)</div>
        <input
          onChange={(e) => setAddress(e.target.value)}
          type="text"
          className="w-full h-10 mb-2 px-4 rounded-full border-white-400 border-2 focus:outline-none focus:border-emerald-100" placeholder="0x00726183671263...." />
        <p className="text-center text-white">{recoveredAddress && "Pasirašančio piniginės adresas: " + recoveredAddress}</p>
        <p className="text-center text-xl font-bold text-lime-500">{recoveredAddress && address && recoveredAddress == address && "Parašas teisingas, adresas sutampa"}</p>
        <p className="text-center text-xl font-bold text-red-700">{recoveredAddress && address && recoveredAddress != address && "Parašas nėra patvirtintas, nesutampa pasirašančio adresas!"}</p>
        <button onClick={() => verify()} className="inline-block bg-lime-300 w-full text-center rounded-full px-6 py-2 mt-6 text-xl font-semibold text-gray-700 mr-2 mb-2">Tikrinti</button>
      </div>
    </div>
  );
}

export default Signature;
