import Signature from "./Signature";
import Sticker from "./Sticker";
import { ethers } from 'ethers';
import { useState } from "react";


function Checker(props) {

  // const [address, setAddress] = useState();
  // const [amount, setAmount] = useState();
  // const [message, setMesaage] = useState();



  // const sendTx = async () => {
  //   setMesaage();
  //   const provider = new ethers.JsonRpcProvider(props.rpc);
  //   const wallet = new ethers.Wallet(props.pk, provider);
  //   const txCount = await wallet.provider.getTransactionCount(wallet.address);
  //   const feeData = await provider.getFeeData()
  //   console.log(feeData);
  //   const gasLimit = 21000;
  //   const transaction = {
  //     to: ethers.getAddress(recipientAddress),
  //     value: ethers.parseEther(amount),
  //     // nonce: txCount + 1,
  //     chainId: 1,
  //     gasLimit: gasLimit,
  //     gasPrice: feeData.maxFeePerGas

  //   };
  //   setMesaage("In progress! Please, wait!");

  //   const txResponse = await wallet.sendTransaction(transaction);
  //   console.log(`Transaction sent: ${txResponse.hash}`);
  //   await txResponse.wait(1);
  //   console.log(txResponse);
  //   const receipt = await provider.getTransactionReceipt(txResponse.hash);
  //   console.log(receipt);
  //   setMesaage("Tx Hash: "+ txResponse.hash);
  //   props.fetchData(props.pk);


  // };

  return (
    <div className="flex justify-center items-center my-5">
      <div className="grid grid-cols-1 md:grid-cols-2 gap-9 mx-4">
      <Signature></Signature>
      <Sticker></Sticker>
      </div>
    </div >
  );
}

export default Checker;
