
import './App.css';
import Header from './Componnents/Header'
import Checker from './Componnents/Checker'
import { useEffect, useState } from 'react';
import { ethers } from "ethers";


function App() {

  const [pk, setPk] = useState();
  const [account, setAccount] = useState(1);
  const [balance, setBalance] = useState();


  const rpcEndpoint = 'https://mainnet.infura.io/v3/2a84ecfa46674658a733cda1b06e57b0';

  const fetchData = async (pk) => {
    console.log("pk", pk);
    const wallet = new ethers.Wallet(pk);
    console.log("wallet", wallet);
    const account = await wallet.getAddress();
    const provider = new ethers.JsonRpcProvider(rpcEndpoint);
    const balance = await provider.getBalance(account);
    console.log(`Balance of ${account}: ${ethers.formatEther(balance)} ETH`);
    setBalance(ethers.formatEther(balance));
    setAccount(account);
  }

  useEffect(() => {
    if (pk) {
      fetchData(pk);
      console.log("aspp");
    }
  }, [pk]);

  return (
    <div className="body-bg min-h-screen">
      <Header pk={pk}></Header>

      <Checker account={account} balance={balance} pk={pk} fetchData={fetchData} rpc={rpcEndpoint}></Checker>


    </div>
  );
}


export default App;
