import { useState } from "react";
import QrReader from "react-qr-reader";


function Sticker(props) {

  const [openCamera, setOpenCamera] = useState(false);
  const [camera, setCamera] = useState("environment");
  const [qrData, setQrData] = useState();


  const handleError = async (data) => {
    console.log("error", data);
  };
  const handleScan = async (data) => {
    if (data) {
      setQrData(data);
    }
  };


  return (
    <div className="w-full rounded overflow-hidden shadow-lg p-4 bg-emerald-600 bg-neutral-800 bg-opacity-40 mb-6 md:mb-0">
      <div className=" px-6 py-4 font-bold text-3xl mb-6 text-white">Lipduko tikrinimas</div>

      {!openCamera && !qrData && <div className="px-6">
        <p className="text- text-white mb-6">
          <h3 className="text-2xl mb-2">Holografinis lipdukas su  QR duomenimis</h3>
          <p className="mb-3 mt-3">
          QR kodo pateikta informacija gali apimti unikalų identifikatorių, pvz., serijos numerį ar kitą informaciją, susietą su holografiniu lipduku. Vartotojas gali palyginti šį identifikatorių su įgaliotų produktų ar dokumentų duomenų baze, kad patikrintų, ar holografinis lipdukas yra autentiškas.          </p>
          <p>
          Be QR kodo, galima patikrinti ir paties holografinio lipduko autentiškumą. Naudotojas gali ištirti holografinį vaizdą ir aptikti klastojimo ar padirbinėjimo požymių, </p>        </p>


        <button onClick={() => setOpenCamera(true)} className="inline-block bg-lime-300 w-full text-center rounded-full px-6 py-2 mt-9 text-xl font-semibold text-gray-700 mr-2 mb-2">Tikrinti</button>

      </div>
      }
      {openCamera && !qrData && <div className="min-w-2/3 w-full mx-auto">
        <p className="text-[12px] text-[#5B546F]">Pasirinkti kamerą</p>

        <select
          name="categories"
          className="text-[#18224C] rounded-lg  focus:outline-none cursor-pointer border-2 mb-3"
          onChange={(e) => setCamera(e.target.value)}
        >
          <option
            value="environment"
            key={1}
            className="option"
            defaultValue
          >
            Galinė
          </option>
          <option value="user" key={2} className="option">
            Priekinė
          </option>
        </select>

        <QrReader
          facingMode={camera}
          delay={100}
          onError={handleError}
          onScan={handleScan}
          className=""
        />
      </div>
      }
      {qrData && <><p className="text-lg font-bold break-all px-6">{qrData}</p>
      <button onClick={() => {setOpenCamera(false); setQrData();}} className="inline-block bg-lime-300 w-full text-center rounded-full px-6 py-2 mt-9 text-xl font-semibold text-gray-700 mr-2 mb-2">Išvalyti</button>
      
      </>}
    </div>
  );
}

export default Sticker;
