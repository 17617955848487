function Header(props) {

  return (
    <nav
      className="flex-no-wrap relative flex w-full items-center justify-between bg-neutral-100 py-4 shadow-md shadow-black/5 dark:shadow-black/10 lg:flex-wrap lg:justify-start bg-emerald-600 bg-opacity-50"
      data-te-navbar-ref>
      <div className="flex w-full flex-wrap items-center justify-between px-8">
        <h1 className="text-white text-2xl opacity-100">HOLO<b>PROOFS</b></h1>
      </div>
    </nav>
  );
}

export default Header;
